import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const DashboardCallCard = ({ img, title, classes, count1, count2 }) => {
  var incoming = count1 || 0;
  var outgoing = count2 || 0;
  const navigate = useNavigate();

  // <<<<<<<<<<<<<<<<<<<<<--For Codeme Domain Only-->>>>>>>>>>>>>>>>>>>>>>>

  // const getCallData = async() => {
  //     try{
  //         const response = await axios.get('https://lead-management-api.foxa.in/api/v1/lead/call/dashboard/data/')
  //         const data = response?.data
  //         if(data?.success){
  //             incoming = data?.data?.incoming
  //             outgoing = data?.data?.outgoing
  //         }

  //     }catch{}
  // }

  // useEffect(()=>{
  //     getCallData()
  // },[])

  // <<<<<<<<<<<<<<<<<<<<<--End Section-->>>>>>>>>>>>>>>>>>>>>>>

  const onCallClick = () => {
    if (title == "Calls") {
      navigate("/reports", { state: { activeTab: 3 } });
    }
  };

  return (
    <div
      className="col-lg-3 col-md-4 my-3"
      onClick={onCallClick}
      style={{ cursor: "pointer" }}
    >
      <div className={"dashboard-card col-12 row p-4 py-3 " + classes}>
        <div className={"col-4 dashboard-card-img my-1 " + classes}>
          <img src={img} className=" dashboard-img-content" alt="" />
        </div>
        <div className="col-8 my-2">
          <div className={"dashboard-card-title " + classes}>{title}</div>
          <div className="dashboard-call-container">
            <div className="incoming-call-card">
              <div className={"dashboard-card-sub2 " + classes}>Incoming</div>
              <div className={"dashboard-call-count " + classes}>
                {incoming < 10 ? "0" + incoming : incoming}
              </div>
            </div>

            <div className="call-seperator"></div>

            <div className="outgoing-call-card">
              <div className={"dashboard-card-sub2 " + classes}>Outgoing</div>
              <div className={"dashboard-call-count " + classes}>
                {outgoing < 10 ? "0" + outgoing : outgoing}
              </div>
            </div>

            <div className="call-clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCallCard;
