import { useEffect, useState } from "react";
import { Checkbox } from "semantic-ui-react";
import Swal from "sweetalert2";
import useAuth from "../../../hooks/useAuth";
import useCompanyServices from "../../../services/companyServices";

function OtherSettingsRaw({ data, refresh }) {
  const [checked, setChecked] = useState("");

  useEffect(() => {
    setChecked(data?.status);
  }, [data]);

  const { auth } = useAuth();
  const {
    changeCompanyPrivacy,
    changeGlobalLeadView,
    changeGlobalCustomerView,
  } = useCompanyServices();

  const handleCheck = async () => {
    try {
      let response;

      if (data.code === "globalView") {
        response = await changeGlobalLeadView();
      } else if (data.code === "leadPrivacy") {
        response = await changeCompanyPrivacy();
      } else if (data.code === "globalCustomerView") {
        response = await changeGlobalCustomerView();
      }

      if (response && response.success) {
        Swal.fire("", "Settings Changed Successfully", "success");
        refresh();
        setChecked(!checked);
      } else {
        Swal.fire("", "Some Errors occurred", "error");
      }
    } catch (err) {
      console.error("Error occurred while changing settings:", err);
      Swal.fire("", "Something went wrong!!", "error");
    }
  };

  return (
    <div className="row border-md-0 border ps-4">
      <div className="col-8 my-1 ps-4 p-1">{data?.name}</div>

      <div className="col-md-1 col-4 ps-md-1 pt-2">
        <Checkbox
          toggle
          checked={checked}
          className=""
          onChange={handleCheck}
        />
      </div>
    </div>
  );
}

export default OtherSettingsRaw;
